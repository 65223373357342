<template>
	<div :class="`theme-main-menu sticky-menu theme-menu-six bg-none mobile-sm ${isSticky ? 'fixed' : ''
		}`">
		<div class="d-flex align-items-center nav-perso ">
			<div class="logo order-lg-1">
				<a href="/">
					<nuxt-img class="logo-EMCI" src="/images/Logos/Emci_logo.png" alt="Logo EMCI" />
				</a>
			</div>
			<nav id="mega-menu-holder" class="navbar navbar-expand-lg order-lg-2">
				<div class="d-flex align-items-center nav-container">
					<button class="navbar-toggler toogle-mobile" data-bs-toggle="collapse"
						data-bs-target="#collapsibleNavbar"
						:class="menuActive ? 'navbar-toggler open' : 'navbar-toggler'"
						@click="menuActive = !menuActive">
						<span></span>
					</button>
					<div :class="menuActive ? 'navbar-collapse collapse ss' : 'navbar-collapse collapse'"
						id="collapsibleNavbar">
						<div class="d-lg-flex justify-content-between align-items-center">
							<ul class="navbar-nav main-side-nav font-gordita" id="one-page-nav">
								<li class="nav-item dropdown">
									<a href="/presentation" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">
										<span @click.self="menuActive = !menuActive">Présentation</span>
									</a>
									<ul class="dropdown-menu">
										<li>
											<a href="/presentation#story" class="dropdown-item">L'histoire</a>
										</li>
										<li>
											<a href="/presentation#equipe" class="dropdown-item">L'équipe</a>
										</li>
										<li>
											<a href="/presentation#valeur" class="dropdown-item">Les valeurs</a>
										</li>
									</ul>
								</li>
								<li class="nav-item dropdown position-static menu-1">
									<a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">
										<i class="fa-sharp fa-light fa-calendar-circle-user me-2 mobile"></i>Nos
										expertises</a>
									<div class="dropdown-menu">
										<mobileMenu v-if="isMobile" />
										<megaMenus v-else />
									</div>
								</li>
								<li class="nav-item dropdown menu-2">
									<a href="/organisme-formation" class="nav-link dropdown-toggle"
										data-bs-toggle="dropdown">
										<span @click.self="menuActive = !menuActive">Organisme de
											formation</span>
									</a>
									<ul class="dropdown-menu organisme">
										<li>
											<a href="/organisme-formation" class="dropdown-item">Soft skills</a>
										</li>
										<li>
											<a href="/organisme-formation" class="dropdown-item">Ressources Humaines et
												RSE</a>
										</li>
										<li>
											<a href="/organisme-formation" class="dropdown-item">Leadership et le
												management</a>
										</li>
									</ul>
								</li>
								<li class="nav-item laptop-view">
									<a :href="`${bilanDeCompetence}`" class="nav-link">
										<span @click.self="menuActive = !menuActive">Bilan de compétences</span>
									</a>
								</li>
								<li class="nav-item menu-3 no-laptop-view">
									<a :href="`${bilanDeCompetence}`" class="nav-link">
										<span @click.self="menuActive = !menuActive"><i
												class="fa-light fa-chart-user me-2 mobile"></i>Bilan de
											compétences</span>
									</a>
								</li>
								<li class="no-laptop-view">
									<a :href="`${offreEmploi}`" class="nav-link emploi">
										<span :class="{ 'active': isActiveMenuItem('contact') }">Offres d'emploi</span>
									</a>
								</li>
								<li class="nav-item no-laptop-view">
									<a :href="`${contactezNous}`" class="nav-link contact">
										<span :class="{ 'active': isActiveMenuItem('contact') }"><u
												class="text-1444px">Nous
												contacter </u> <i class="fa-regular fa-phone"></i></span>
									</a>
								</li>
								<li class="nav-item dropdown laptop-view">
									<a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Plus</a>
									<div class="dropdown-menu laptop">
										<ul>
											<li class="nav-item menu-3" id="bilan_de_competences">
												<a :href="`${bilanDeCompetence}`" class="nav-link"><span
														@click.self="menuActive = !menuActive">Bilan de
														compétences</span>
												</a>
											</li>
											<li class="nav-item">
												<a :href="`${offreEmploi}`" class="nav-link emploi"><span
														@click.self="menuActive = !menuActive">Offres
														d'emploi</span></a>
											</li>
											<li class="nav-item">
												<a :href="`${contactezNous}`" class="nav-link contact"><span
														@click.self="menuActive = !menuActive">Nous contacter <i
															class="fa-regular fa-phone"></i></span></a>
											</li>
										</ul>
									</div>
								</li>
								<li class="nav-item desktop">
									<a href="https://www.linkedin.com/company/emci-conseil/" class="nav-link linkedin"
										target="_blank">
										<i class="fa-brands fa-linkedin"></i>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</nav>
		</div>
	</div>
</template>
<script setup>
import megaMenus from "./mega-menus.vue";
import mobileMenu from "./mobile-menu.vue";

defineProps({

});

const isSticky = ref(false);
const menuActive = ref(false);
const isMobile = ref(false);

const handleSticky = () => {
	if (window.scrollY > 80) {
		isSticky.value = true;
	} else {
		isSticky.value = false;
	}
};

const bilanDeCompetence = "/bilan_competences";
const contactezNous = "/contactez-nous";
const offreEmploi = "/offre-emploi";

const isActiveMenuItem = (item) => {
	const currentPage = useRoute().path;

	switch (item) {
		case 'entreprise':
			return currentPage.includes('/Entreprise');
		case 'voyages':
			return currentPage.includes('/Nos-voyages');
		case 'transport':
			return currentPage.includes('/Transports');
		case 'devis':
			return currentPage === '/devis';
		case 'recrutement':
			return currentPage.includes('/recrutements');
		default:
			return false;
	}
};

onMounted(() => {
	const checkScreenSize = () => {
		isMobile.value = window.innerWidth < 768; // Ajustez le point de rupture selon vos besoins
	};
	checkScreenSize();
	window.addEventListener('resize', checkScreenSize);
	window.addEventListener("scroll", handleSticky);
});

onBeforeUnmount(() => {
	window.removeEventListener("scroll", handleSticky);
	window.removeEventListener('resize', checkScreenSize);
});
</script>

<style>
u,
.text-1444px {
	text-decoration: none !important;
}

u,
.text-1444px:hover {
	text-decoration: none !important;
}


.theme-main-menu.sticky-menu.theme-menu-six {
	box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.08) !important;
	background-color: #FFF;
}

.theme-main-menu {
	padding: 20px 73px;
	align-items: center;
}

.logo-EMCI {
	max-width: 250px;
}

.nav-perso {
	justify-content: space-between;
}


.contact {
	background-color: var(--bleu-fonce);
	border: 2px solid var(--bleu-fonce);
	color: white !important;
	padding: 10px 20px !important;
	border-radius: 35px;
}

.contact:hover {
	background-color: transparent;
	border: 2px solid var(--bleu-fonce);
	color: var(--bleu-fonce) !important;
}

.emploi {
	/*background-color: var(--rouge);*/
	border: 2px solid var(--rouge);
	color: var(--rouge) !important;
	padding: 10px 20px;
	border-radius: 35px;
}

.emploi:hover {
	background: #F9E1E2;
	border: 2px solid #F9E1E2;
	color: var(--rouge) !important;
}

.fa-magnifying-glass,
.fa-linkedin {
	color: #318EC7;
	font-size: 24px;
}

.nav-item a,
.nav-link span {
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 144%;
	/* 25.92px */
	letter-spacing: 0.72px;
}

.nav-item a:hover {
	text-decoration: underline;
}

.mobile {
	display: none;
}

.desktop {
	display: block;
}

.dropdown-item {
	text-transform: none !important;
}

.mega-menu li a {
	text-decoration: none !important;
}

.nav-item,
.nav-link.emploi {
	white-space: nowrap;
}

.no-laptop-view {
	display: block;
}

.laptop-view {
	display: none;
}

.navbar-nav .nav-item .nav-link {
	text-transform: initial;
}

.dropdown-menu.organisme {
	min-width: 300px;
}


/* Styles pour les écrans de petite taille (S) */
@media only screen and (max-width: 599px) {

	html,
	body {
		overflow-x: hidden;
	}

	.mobile {
		display: inline-block;
	}

	.desktop {
		display: none;
	}

	.dark-style .theme-main-menu {
		background: #FFF;
	}

	.theme-main-menu {
		min-width: 245%;
	}

	.nav-perso {
		width: 40% !important;
	}

	.logo-EMCI {
		max-width: 100%;
		min-width: 75%;
		width: 97%;
	}

	.nav-container {
		margin-right: 0px;
	}

	.toogle-mobile {
		border-radius: 50%;
		min-width: 40px;
		min-height: 40px;
		padding: 16px;
		margin: 5px;
		background: #25233e !important;
	}

	.dark-style.theme-main-menu.sticky-menu.fixed.mobile-sm {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 1000;
		background: white;
		border-bottom: 1px solid var(--black);
	}

	.main-page-wrapper {
		padding-top: 0;
		position: fixed !important;
		z-index: 10000;
	}

	.theme-main-menu {
		padding: 16px 10px;
	}

	.navbar-toggler::before,
	.navbar-toggler::after {
		left: 27%;
		width: 19px;
	}

	.dark-style .navbar-collapse {
		background: #FFF;
	}

	.navbar-toggler span {
		left: 27%;
		width: 19px;
	}

	.navbar-collapse {
		max-height: 100vh;
	}

	.navbar .navbar-nav {
		font-family: 'Inter';
		text-align: left;
		align-items: flex-start;
	}

	.navbar-collapse.collapse.ss.show {
		width: 40%;
	}

	.menu-1 {
		background-color: #318EC7;
		color: white !important;
	}

	.menu-2 {
		background-color: #FAB74B;
		color: white !important;
	}

	.menu-3 {
		background-color: var(--rouge);
		color: white !important;
		margin-bottom: 1rem;
	}

}


/* Styles pour les écrans de taille moyenne (M) */
@media only screen and (min-width: 600px) and (max-width: 768px) {

	html,
	body {
		overflow-x: hidden;
	}


	.logo-EMCI {
		max-width: 200px !important;
	}

	.mobile {
		display: inline-block;
	}

	.desktop {
		display: none;
	}

	.dark-style .theme-main-menu {
		background: #FFF;
	}

	.theme-main-menu {
		min-width: 245%;
	}

	.nav-perso {
		width: 40% !important;
	}

	.logo-EMCI {
		max-width: 100%;
		min-width: 75%;
		width: 97%;
	}

	.nav-container {
		margin-right: 0px;
	}

	.toogle-mobile {
		border-radius: 50%;
		min-width: 40px;
		min-height: 40px;
		padding: 16px;
		margin: 5px;
		background: #25233e !important;
	}

	.navbar-toggler::before,
	.navbar-toggler::after {
		left: 27%;
		width: 19px;
	}

	.dark-style.theme-main-menu.sticky-menu.fixed.mobile-sm {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 1000;
		background: white;
		border-bottom: 1px solid var(--black);
	}

	.main-page-wrapper {
		padding-top: 0;
		position: fixed !important;
		z-index: 10000;
	}

	.theme-main-menu {
		padding: 16px 10px;
	}



	.dark-style .navbar-collapse {
		background: #FFF;
	}

	.navbar-toggler span {
		left: 27%;
		width: 19px;
	}

	.navbar .navbar-nav {
		font-family: 'Inter';
		text-align: left;
		align-items: flex-start;
	}

	.navbar-collapse.collapse.ss.show {
		width: 40%;
	}

	.menu-1 {
		background-color: #318EC7;
		color: white !important;
	}

	.menu-2 {
		background-color: #FAB74B;
		color: white !important;
	}

	.menu-3 {
		background-color: var(--rouge);
		color: white !important;
		margin-bottom: 1rem;
	}

}

@media only screen and (min-width: 769px) and (max-width: 991px) {

	html,
	body {
		overflow-x: hidden;
	}

	.logo-EMCI {
		max-width: 200px !important;
	}

	.mobile {
		display: inline-block;
	}

	.desktop {
		display: none;
	}

	.dark-style .theme-main-menu {
		background: #FFF;
	}

	.theme-main-menu {
		min-width: 245%;
	}

	.nav-perso {
		width: 40% !important;
	}

	.logo-EMCI {
		max-width: 100%;
		min-width: 75%;
		width: 97%;
	}

	.nav-container {
		margin-right: 0px;
	}

	.toogle-mobile {
		border-radius: 50%;
		min-width: 40px;
		min-height: 40px;
		padding: 16px;
		margin: 5px;
		background: #25233e !important;
	}

	.navbar-toggler::before,
	.navbar-toggler::after {
		left: 27%;
		width: 19px;
	}

	.dark-style.theme-main-menu.sticky-menu.fixed.mobile-sm {
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 1000;
		background: white;
		border-bottom: 1px solid var(--black);
	}

	.main-page-wrapper {
		padding-top: 0;
		position: fixed !important;
		z-index: 10000;
	}

	.theme-main-menu {
		padding: 16px 10px;
	}



	.dark-style .navbar-collapse {
		background: #FFF;
	}

	.navbar-toggler span {
		left: 27%;
		width: 19px;
	}

	.navbar .navbar-nav {
		font-family: 'Inter';
		text-align: left;
		align-items: flex-start;
	}

	.navbar-collapse.collapse.ss.show {
		width: 40%;
	}

	.menu-1 {
		background-color: #318EC7;
		color: white !important;
	}

	.menu-2 {
		background-color: #FAB74B;
		color: white !important;
	}

	.menu-3 {
		background-color: var(--rouge);
		color: white !important;
		margin-bottom: 1rem;
	}
}

@media only screen and (min-width: 1000px) and (max-width: 1024px) {
	.logo-EMCI {
		max-width: 200px !important;
	}

	.no-laptop-view {
		display: none;
	}

	.laptop-view {
		display: block;
	}

	.nav-perso {
		max-width: 87%;
	}

	.dropdown-menu.laptop {
		position: absolute;
		left: -250px !important;
	}

	.dropdown-menu.laptop ul {
		list-style: none;
		/* Supprimer les puces de la liste */
		padding: 0;
		/* Supprimer le remplissage de la liste */
	}

	.dropdown-menu.laptop .nav-link {
		display: block;
		padding: 10px;
		/* Espacement intérieur des liens */
		text-align: center;
		/* Centrer le texte */
	}

	.dropdown-menu.laptop .nav-link span {
		display: inline-block;
		font-size: 16px;
		/* Taille de la police */
		width: 100%;
		/* Rendre le texte entièrement cliquable */
	}

	/* Espacement entre les liens */
	.dropdown-menu.laptop .nav-item+.nav-item {
		margin-top: 10px;
	}

	.animation-img.triangle[data-v-f992991b],
	.animation-img-delay-1.carre[data-v-f992991b],
	.animation-img-delay-2.rond[data-v-f992991b] {
		width: 20%;
	}
}

@media only screen and (min-width: 1025px) and (max-width: 1087px) {

	.logo-EMCI {
		max-width: 200px !important;
	}

	.nav-link.contact span .text-1444px {
		display: none;
	}



	.nav-perso {
		max-width: 80%;
	}

	.dropdown-menu.laptop {
		position: absolute;
		left: -250px !important;
	}

	.dropdown-menu.laptop ul {
		list-style: none;
		/* Supprimer les puces de la liste */
		padding: 0;
		/* Supprimer le remplissage de la liste */
	}

	.dropdown-menu.laptop .nav-link {
		display: block;
		padding: 10px;
		/* Espacement intérieur des liens */
		text-align: center;
		/* Centrer le texte */
	}

	.dropdown-menu.laptop .nav-link span {
		display: inline-block;
		font-size: 16px;
		/* Taille de la police */
		width: 100%;
		/* Rendre le texte entièrement cliquable */
	}

	/* Espacement entre les liens */
	.dropdown-menu.laptop .nav-item+.nav-item {
		margin-top: 10px;
	}


}


@media only screen and (min-width: 1088px) and (max-width: 1381px) {

	.theme-menu-six .navbar-nav .nav-item .nav-link,
	.nav-item a,
	.nav-link span {
		font-size: 14px;
		margin: 0 8px;
		white-space: nowrap;
	}

	.theme-main-menu {
		padding: 20px 10px;
		align-items: center;
	}

	.logo-EMCI {
		max-width: 165px !important;
	}

	.emploi {
		padding: 5px 10px;
	}

	.navbar .dropdown-menu .dropdown-item {
		font-size: 0.775rem;
	}

	#mega-menu-holder .dropdown-toggle::after {
		right: -13px;
		top: 38%;
	}

}

@media only screen and (min-width: 1400px) and (max-width: 1550px) {
	.theme-main-menu {
		padding: 4px 50px;
	}

	.logo-EMCI {
		max-width: 200px !important;
	}

	.nav-link.contact span .text-1444px {
		display: block;
	}

	.contact span {
		white-space: nowrap !important;
	}

	.contact span u,
	.contact span i {
		display: inline-block !important;
		vertical-align: middle !important;
	}

	.emploi {
		padding: 5px 40px;
	}

	p,
	.bloctitleText,
	a,
	a span,
	.small_desc {
		font-size: 0.813rem !important;
		font-family: Inter;
	}
}

@media only screen and (min-width: 1550px) and (max-width: 1800px) {
	.nav-link.contact span .text-1444px {
		display: block;
	}

	.contact span {
		white-space: nowrap !important;
	}

	.contact span u,
	.contact span i {
		display: inline-block !important;
		vertical-align: middle !important;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.theme-menu-six .navbar-nav .nav-item .nav-link {
		font-size: 18px;
		margin: 0 13px;
	}

	.theme-main-menu {
		padding: 20px 15px;
	}
}

@media screen and (min-width: 320px) {}

@media screen and (min-width: 375px) {}

@media screen and (min-width: 425px) {}

@media screen and (min-width: 768px) {}

@media screen and (min-width: 1024px) {}

@media screen and (min-width: 1440px) {}

@media screen and (min-width: 2559px) {}
</style>

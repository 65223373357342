<template>
    <section class="theCookies" v-if="showCookies">
        <div class="mainPanel" v-if="main">
            <span class="cookieTitle">
                Gérer mes cookies
            </span>
            <div class="textCookie">
                <p class="cookiesubtitle">Utilisation</p>
                <p>
                    Notre site EMCI utilise des cookies pour l'analyse des audiences (Google Analytics)
                    afin de nous permettre de mieux comprendre le comportement de nos visiteurs et d'améliorer
                    l'expérience
                    utilisateur.
                </p>
                <p class="cookiesubtitle">Conservation</p>
                <p>Nous conservons votre choix pendant 6 mois</p>
            </div>
            <div class="buttonsCookie">
                <div class="perso" @click="switchCustomCookies()">
                    Personnaliser mes choix
                </div>
                <div class="refuse" @click="CookiesAreAccepted(false)">
                    Tout refuser
                </div>
                <div class="accept" @click="CookiesAreAccepted(true)">
                    Tout accepter
                </div>
            </div>
        </div>
        <div class="persoPanel" v-if="perso">
            <div class="cookiesBloc">
                <div>
                    <div class="cookieTitle">
                        Gérer mes cookies
                        <span class="spanTitle">Personnalisation des choix</span>
                        <br />
                        <p>Nous conservons votre choix pendant 6 mois.</p>
                    </div>
                    <ul class="choiceList">
                        <li>
                            <div class="cookieBlock choice">
                                <label for="googleAnalytics">
                                    <input type="checkbox" id="googleAnalytics" name="googleAnalytics" value="ga"
                                        v-model="optionnalScripts">
                                    Améliorations de l'expérience utilisateur
                                </label>
                                <p class="describe">
                                    Ceux-ci sont utilisés pour l'analyse des audiences
                                    grâce à
                                    <a href="https://marketingplatform.google.com/about/analytics/" target="_blank"
                                        rel="noreferer nofollow"
                                        title="Cliquez pour en savoir plus sur Google Analytics...">Google Analytics</a>
                                    afin de nous permettre de mieux comprendre le
                                    comportement de nos visiteurs et d'améliorer
                                    l'expérience utilisateur.
                                </p>
                            </div>
                        </li>
                        <li>
                            <div class="cookieBlock choice">
                                <label for="necessaryCookie">
                                    <input type="checkbox" id="necessaryCookie" name="necessaryCookie" checked="checked"
                                        readonly="readonly" disabled="disabled">
                                    Cookies nécessaires au bon fonctionnement du site
                                </label>
                                <p class="describe">
                                    Les cookies internes émis par
                                    EMCI sont des cookies fonctionnels. Ils
                                    sont nécessaires au bon fonctionnement du site et ne
                                    requièrent pas de procédure d’acceptation.
                                </p>
                            </div>
                        </li>
                    </ul>
                    <div class="cookieControllers">
                        <button class="accept" @click="CookiesAreAccepted(true)">
                            Tout accepter
                        </button>
                        <button class="accept"
                            @click="optionnalScripts ? CookiesAreAccepted(true) : CookiesAreAccepted(false)">
                            Valider mes choix
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script setup>

useHead({
    // script: [
    //     {
    //         type: "text/javascript",
    //         src: "https://www.googletagmanager.com/gtag/js?id=G-GTHHB06JTH",
    //         async: true
    //     }
    // ]
})
const showCookies = ref(false);
const optionnalScripts = ref(true);
const main = ref(true)
const perso = ref(false)
const etat = useCookie("acptCks", {
    maxAge: 15552000
});
const verifyExistingCookies = () => {
    if (navigator.cookieEnabled) {
        if (etat.value == "" || etat.value == undefined) {
            showCookies.value = true;
        }
        if (etat.value == "all") {
            CookiesAreAccepted(true);
        }
        if (etat.value == "no") {
            CookiesAreAccepted(true);
        }
    }
}
const CookiesAreAccepted = (param) => {
    if (optionnalScripts.value && param === true) {
        // this.setCookie("acptCks", "all", 182);
        etat.value = "all"
    } else {
        etat.value = "no"
        optionnalScripts.value = false;
    }

    if (param) {
        // Google Analytics
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'G-GTHHB06JTH');

        console.log("GA active Tracking")
    }

    showCookies.value = false
}
const switchCustomCookies = () => {
    main.value = false
    perso.value = true
}
onMounted(() => {
    verifyExistingCookies()
})
</script>
<style scoped>
span {
    color: #0b9bd8;
    margin: 1rem 0;
}

.theCookies {
    position: fixed;
    inset: 0;
    z-index: 9999;
    display: grid;
    grid-template-areas: "stack";
    place-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.textCookie {
    margin-top: 25px;
}

.theCookies .mainPanel,
.theCookies .persoPanel {
    background-color: #fefefe;
    box-shadow: 2px 2px 8px 0 #777;
    grid-area: stack;
    line-height: 1;
    max-width: 70%;
    padding: 2rem;
}

.cookieTitle {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 2rem;
    color: #318EC7;
    margin: 2rem 0;
}

.theCookies .mainPanel .cookieTitle span,
.theCookies .persoPanel .cookieTitle span,
.theCookies .mainPanel .cookieTitle p,
.theCookies .persoPanel .cookieTitle p {
    color: initial;
    font-size: 1rem;
    color: var(--main-color);
    text-transform: initial;
}

@media all and (max-width: 768px) {

    .theCookies .mainPanel .cookieTitle .spanTitle,
    .theCookies .persoPanel .cookieTitle .spanTitle {
        font-size: 0.5rem;
    }
}

@media all and (max-width: 768px) {

    .theCookies .mainPanel .cookieTitle,
    .theCookies .persoPanel .cookieTitle {
        font-size: 1rem;
    }
}


.theCookies .mainPanel .textCookie .cookiesubtitle,
.theCookies .persoPanel .textCookie .cookiesubtitle {
    font-weight: 700;
    font-size: 1.5rem;
    color: var(--main-color);
    margin: 2rem 0;
}

.theCookies .mainPanel .buttonsCookie,
.theCookies .persoPanel .buttonsCookie,
.theCookies .mainPanel .cookieControllers,
.theCookies .persoPanel .cookieControllers {
    display: flex;
    justify-content: space-evenly;
}

.theCookies .mainPanel .buttonsCookie .perso,
.theCookies .persoPanel .buttonsCookie .perso,
.theCookies .mainPanel .cookieControllers .perso,
.theCookies .persoPanel .cookieControllers .perso {
    background: #318EC7;
    ;
    color: white;
    border: 2px transparent solid;
    padding: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 0.5rem;
    transition: all 0.5s ease;
    cursor: pointer;
    border: 1px solid;
    margin-top: 2rem;
}

.theCookies .mainPanel .buttonsCookie .perso:hover {
    background-color: white;
    border: 1px solid #318EC7;
    ;
    color: #318EC7;
}

.theCookies .mainPanel .buttonsCookie .refuse,
.theCookies .persoPanel .buttonsCookie .refuse,
.theCookies .mainPanel .cookieControllers .refuse,
.theCookies .persoPanel .cookieControllers .refuse {
    background: #318EC7;
    ;
    color: white;
    border: 2px transparent solid;
    padding: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 0.5rem;
    transition: all 0.5s ease;
    cursor: pointer;
    border: 1px solid;
    margin-top: 2rem;
}


.theCookies .mainPanel .buttonsCookie .refuse:hover {
    background-color: white;
    border: 1px solid #318EC7;
    ;
    color: #318EC7;
}


.theCookies .mainPanel .buttonsCookie .accept,
.theCookies .persoPanel .buttonsCookie .accept,
.theCookies .mainPanel .cookieControllers .accept,
.theCookies .persoPanel .cookieControllers .accept {
    background: #318EC7;
    color: white;
    border: 2px transparent solid;
    padding: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 0.5rem;
    transition: all 0.5s ease;
    cursor: pointer;
    border: 1px solid;
    margin-top: 2rem;
}

.theCookies .mainPanel .buttonsCookie .accept:hover,
.theCookies .persoPanel .cookieControllers .accept:hover {
    background-color: white;
    border: 1px solid #318EC7;
    color: #318EC7;
}

/* 
.theCookies .mainPanel .buttonsCookie .accept,
.theCookies .persoPanel .buttonsCookie .accept,
.theCookies .mainPanel .cookieControllers .accept,
.theCookies .persoPanel .cookieControllers .accept {
    background: var(--main-color);
    color: white;
} */

@media all and (max-width: 768px) {

    .theCookies .mainPanel .buttonsCookie,
    .theCookies .persoPanel .buttonsCookie,
    .theCookies .mainPanel .cookieControllers,
    .theCookies .persoPanel .cookieControllers {
        flex-direction: column;
        row-gap: 3vw;
    }
}

.theCookies .choiceList {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.theCookies .choiceList .choice {
    background-color: #eee;
    padding: 0.5rem;
}

.theCookies .choiceList .choice p {
    font-size: 0.8rem;
}

.theCookies .choiceList .choice .describe a {
    color: var(--main-color);
    text-decoration: underline;
}

.theCookies .choiceList .choice input {
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.theCookies .choiceList .cookieBlock label {
    color: var(--bleu-fonce);
    display: flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
}
</style>
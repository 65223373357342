<template>
    <ul class="navbar-nav">
        <li class="nav-item" v-for="(menu, index) in homes_data" :key="index">
            <a class="nav-link" href="#" :id="`navbarDropdownSubItem-${index}`">
                <i :class="`fa-regular ${menu.fontawesome} me-2`"></i>{{ menu.headerTitle }}
            </a>
            <ul class="submenu" :aria-labelledby="`navbarDropdownSubItem-${index}`"
                :class="{ 'active-submenu': menu.showSubmenu }">
                <li v-for="(submenu, subIndex) in menu.Submenu" :key="subIndex">
                    <a class="dropdown-item" :href="submenu.url">{{ submenu.nameLink }}</a>
                </li>
            </ul>
        </li>
    </ul>
</template>

<script setup>
import { ref } from 'vue';
import menuData from '@/data/menu/menu.json';
const homes_data = ref(menuData);

</script>

<style scoped>
@media only screen and (min-width: 300px) and (max-width: 768px) {

    .menu-1 a,
    .menu-3 a {
        color: #756f6f !important;
    }

    .submenu {
        display: block;
    }

    .active-submenu {
        background-color: red;
    }

    .nav-link {
        white-space: break-spaces;
        background-color: var(--tertiaire-300, #D2EEFF);
    }
}
</style>
<template>
	<ul class="mega-menu d-flex">
		<li v-for="item in homes_data" :key="item.id">
			<div class="w-100">
				<div class="box-header">
					<img :src="'/images/pictos/' + item.headerPicto + '.png'" :alt="item.headerTitle">{{
			item.headerTitle }}
				</div>
			</div>
			<a v-for="subCat in item.Submenu" :href="subCat.url" class="dropdown-item img-box">
				<span class="font-rubik">{{ subCat.nameLink }}</span>
			</a>
		</li>
	</ul>
</template>

<script setup>
import menuData from '@/data/menu/menu.json';
const homes_data = ref(menuData);
</script>


<style scoped>
.w-100 {
	cursor: default;
}

.box-header:hover {
	background: linear-gradient(93deg, #318EC7 9.92%, #E94B54 52.75%, #FAB74B 88.05%);
}

.box-header {
	font-family: 'Inter';
	font-size: 18px;
}

@media only screen and (max-width: 1000px) {

	.emploi {
		padding: 5px 20px;
		margin-bottom: 5px;

	}
}

@media only screen and (max-width: 1024px) {

	.font-rubik,
	.box-header {
		white-space: break-spaces;
		padding-right: 10px;
	}
}
</style>
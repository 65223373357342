<template>
  <footer :class="`theme-footer-seven ${dark_bg ? 'dark-bg pt-120 md-pt-100' : 'mt-120 md-mt-100'
    }`">
    <div class="lg-container">
      <div class="row">
        <div class="col-xl-3 col-lg-2 col-sm-6 col-md-12 mb-40 flex-5">
          <div class="logo">
            <a href="/">
              <nuxt-img src="/images/Logos/logo-footer-emci.png" alt="Logo EMCI" />
            </a>
          </div>
          <div class="pt-30 position-relative">
            <p class="address">
              E.M.C.I. Toulouse (Siège) <br />
              116 route d'Espagne, bâtiment Hélios 3 <br />
              31100 TOULOUSE
            </p>
          </div>
        </div>
        <div class="col-lg-2 col-md-12 col-sm-6 mb-40 flex-2">
          <h5 class="title">Contact</h5>
          <p class="address">
            <a href="mailto:admin@emciconseil.fr"><span>admin@emciconseil.fr</span></a> <br />
            <a href="tel:0562471909"><span>05 62 47 19 09</span></a> <br /> <br />
          </p>
          <div class="linkedin">
            <a href="https://www.linkedin.com/company/emci-conseil/" target="_blank">
              <i class="fa-brands fa-linkedin"></i>
            </a>
          </div>
        </div>
        <div class="col-lg-2 col-md-12 col-sm-6 mb-40 flex-2">
          <h5 class="title">Nos expertises</h5>
          <ul class="footer-list">
            <li><a href="/conseil_rh/pcrh">Conseil RH</a></li>
            <li><a href="/qualite-vie-condition-travail/prevention-rps">Qualité de vie et conditions de travail</a></li>
            <li><a href="/management/accompagnement_codir">Management</a></li>
            <li><a href="/transition-professionnelle/outplacement">Transition professionnelle</a></li>
            <li><a href="/recrutement/recrutement">Recrutement</a></li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-12 col-sm-6 mb-40 flex-3 d-flex  flex-column">
          <a href="/organisme-formation">
            <h5 class="title">Organisme de formation</h5>
          </a>
          <a :href="pdf1" target="_blanks">
            <h5 class="title">Catalogue de formation</h5>
          </a>
          <a href="/bilan_competences">
            <h5 class="title">Bilan de compétences</h5>
          </a>
          <a href="/actualites">
            <h5 class="title">Nos Actualités</h5>
          </a>
          <div class="w-70">
            <div class="newsletter">
              <a href="/offre-emploi">
                <h5 class="title">Offres d'emploi</h5>
              </a>
            </div>
          </div>
        </div>
        <div
          class="col-xl-4 col-lg-5 col-sm-6  col-md-12 mb-40 flex-3 d-flex flex-column justify-content-between align-items-center offre">
          <div>
            <a :href="pdf" target="_blank">
              <nuxt-img src="/images/Logos/logo-qualiopi.png" alt="logo qualiopi" class="qualiopi-footer-img" />
            </a>
          </div>
          <div>
            <p class="cert">
              La certification qualité a été délivrée au titre des catégories d’actions suivantes :
            </p>
            <ul class="cert ul">
              <li class="cert-ul-li">ACTIONS DE FORMATION</li>
              <li class="cert-ul-li">BILAN DE COMPÉTENCES</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="cityList d-flex justify-content-between flex-wrap-mobile">
        <span v-for="(c, index) in city" :key="index" class="textLimitation">{{ c }} {{ (index !== (city.length - 1)) ?
    '|&nbsp;' : '' }}</span>
      </div>

      <div class="bottom-footer">
        <div class="row margin-mobile">
          <div class="col-lg-3 order-lg-1 mb-20">

          </div>
          <div class="col-lg-3 order-lg-3 mb-20">

          </div>
          <div class="col-lg-6 order-lg-2 mb-20">
            <p class="copyright text-center flex-wrap-mobile signed-graphibox">
              Tous droits réservés ©2023 - <a href="/mentions-legales" target="_blank">Mentions légales</a> -
              <a href="/formulaire-reclamation">Formulaire de réclamation</a> - <a href="https://www.graphibox.biz/"
                target="_blank">Graphibox</a>
            </p>
          </div>
        </div>
      </div>

    </div>
    <!-- /.lg-container -->
    <Cookies />
  </footer>
</template>

<script setup>
import Cookies from "@/components/emci/Cookies/index.vue";
import { ref } from 'vue';
import axios from 'axios';

// Define component props
const props = defineProps({
  dark_bg: Boolean,
});

// Define reactive state
const city = ref([
  'Toulouse',
  'Blagnac',
  'Albi',
  'Cahors',
  'Figeac',
  'Montauban',
  'Quint-fonsegrives',
  'Annecy',
  'Bayonne',
  'Bordeaux',
  'Lyon',
  'Montpellier',
  'Marseille',
  'Nantes',
  'Paris',
  'Reims',
  'Rouen',
  'Strasbourg'
]);

const pdf = ref('');
const pdf1 = ref('');

onMounted(async () => {
  try {
    const response = await axios({
      method: 'post',
      url: 'https://ws2-gbbu02.graphibox.eu/EpDirectories.ws',
      data: { pKey: 'EmPA5d6sdse5z6ldfjPAEhazozp5ksJEZkzn872', pFct: 'GetDirectories', pIdCat: '70' },
    });
    const files = response.data[0];
    pdf.value = `https://cdn-gbbu02.graphibox.eu/pub/${files.siteIdD}/medias/directories/${files.idD}/${files.file1D}`;

    const response1 = await axios({
      method: 'post',
      url: 'https://ws2-gbbu02.graphibox.eu/EpDirectories.ws',
      data: { pKey: 'EmPA5d6sdse5z6ldfjPAEhazozp5ksJEZkzn872', pFct: 'GetDirectories', pIdCat: '69' },
    });
    const files1 = response1.data[0];
    pdf1.value = `https://cdn-gbbu02.graphibox.eu/pub/${files1.siteIdD}/medias/directories/${files1.idD}/${files1.file1D}`;

  } catch (error) {
    console.error('Erreur lors du chargement des données :', error);
  }
});
</script>

<style scoped>
.textLimitation {
  white-space: nowrap;
}

.theme-footer-seven.dark-bg {
  background: #25233E;
  padding-left: 130px;
  padding-right: 130px;
}

.address {
  color: #fff;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 144%;
  /* 1.62rem */
  letter-spacing: 0.045rem;
}


.flex-5 {
  flex: 5;
}

.flex-3 {
  flex: 3;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.title {
  color: #FFF !important;
  font-family: Inter;
  font-size: 1.115rem;
  font-style: normal;
  font-weight: 500;
  line-height: 133%;
  /* 1.74563rem */
  letter-spacing: 0.0525rem;
  text-decoration-line: underline;
  text-transform: initial;
}

.footer-list li a {
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 300;
  line-height: 144%;
  /* 1.62rem */
  letter-spacing: 0.045rem;
  margin-top: .5rem;
}

.footer-list li a:hover {
  text-decoration: none;
}

.contact {
  background-color: transparent;
  border: 1.5px solid #fff;
  color: white !important;
  padding: 10px 20px !important;
  border-radius: 35px !important;
  width: 100% !important;
  column-gap: 1rem;

  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 144%;
  /* 1.62rem */
  letter-spacing: 0.045rem;
}

.contact:hover {
  color: #fff !important;
  border-color: transparent;
  background: linear-gradient(93deg, #318EC7 9.92%, #E94B54 52.75%, #FAB74B 88.05%);
}

.cert {
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 145%;
  /* 1.45rem */
  letter-spacing: 0.04rem;
  color: #fff;
}

.ul {
  list-style-type: initial;
  padding: revert;
}

.offre {
  row-gap: 2rem;
}

.cityList {
  width: 100% !important;
  font-family: Inter;
  font-size: 0.95rem;
  font-style: normal;
  font-weight: 300;
  line-height: 145%;
  /* 1.45rem */
  letter-spacing: 0.04rem;
  flex-wrap: wrap;
  overflow-wrap: break-word;
}

.bottom-footer {
  border-top: 1px solid #fff !important;
}

.copyright {
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  /* 1.45rem */
  letter-spacing: 0.04rem;
}

.linkedin i {
  font-size: 2rem;
  color: #FFF;
}

.w-70 {
  width: 70%;
}

a {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 768px) {

  .flex-5,
  .flex-3,
  .flex-1,
  .flex-2 {
    flex: none !important;
  }
}

/* Styles pour les écrans de petite taille (S) */
@media only screen and (max-width: 599px) {

  footer {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .cert {
    font-size: 0.8rem;
  }

  .cert-ul-li {
    font-size: 0.6rem !important;
  }

  .qualiopi-footer-img {
    max-width: 90% !important;
  }

  .lg-container {
    margin-top: -3rem;
  }

  .lg-container img {
    max-width: 80%;
  }

  .address,
  .footer-list li a {
    font-size: 0.8rem;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .title {
    font-size: 1rem;
    text-align: left;
  }

  .contact {
    font-size: 0.7rem;
    white-space: nowrap;
  }

  .flex-5,
  .flex-3,
  .flex-1,
  .flex-2 {
    flex: none !important;
  }

  .flex-wrap-mobile {
    flex-wrap: wrap;
    margin-left: 0%;
    width: 100%;
    letter-spacing: 0.07225rem;
    font-size: 0.8rem;
    margin-left: 0px !important;
  }

  footer {
    text-align: justify;
    font-family: Inter;
  }


  .margin-mobile {
    margin: 0rem;
  }

  .signed-graphibox {
    padding-left: 0;
    font-size: 0.8rem !important;
  }

  .col-lg-6.order-lg-2.mb-20 {
    margin-left: 10px;
    margin-top: -40px !important;
    width: 80% !important;
  }

  .lg-container .container .row {
    position: relative !important;
    left: -40% !important;
    width: 70% !important;
  }

}

@media screen and (min-width: 600px) and (max-width: 991px) {
  .signed-graphibox {
    margin-left: -10% !important;
  }

  .cityList {
    margin: 4px 1rem;
    margin-left: -35px !important;
  }

  .contact {
    width: 40% !important;
  }

  .flex-5,
  .flex-3,
  .flex-1,
  .flex-2 {
    flex: none !important;
  }

  .flex-wrap-mobile {
    flex-wrap: wrap;
    margin-left: 10.2rem;
  }

  footer {
    position: absolute;
    left: 0%;
    padding-left: 10% !important;
  }

  .justify-content-between,
  .align-items-center {
    justify-content: initial !important;
    align-items: initial !important;
  }

  .margin-mobile {
    margin: 0rem;
  }

}


@media only screen and (min-width: 1000px) and (max-width: 1024px) {

  .col-lg-2 {
    width: 31%;
  }

  .signed-graphibox {
    padding-left: -5rem !important;
    font-size: 0.8rem !important;
    white-space: nowrap;
  }

  .flex-5,
  .flex-3,
  .flex-1,
  .flex-2 {
    flex: none !important;
  }

  .flex-wrap-mobile {
    flex-wrap: wrap;
  }

  .theme-footer-seven.dark-bg {
    padding-left: 70px;
    padding-right: 70px;
  }

  footer {
    position: absolute;
  }

  .mobile {
    white-space: nowrap;
  }

}

/** ecran 1280 * 1024  */

@media only screen and (min-width: 1088px) and (max-width: 1381px) {
  .theme-footer-seven.dark-bg {
    padding-left: 100px;
    padding-right: 100px;
  }

  .logo {
    max-width: 80%;
  }

  .title {
    font-size: 0.9115rem;
  }

  p span {
    font-size: 0.8115rem;
  }

  .footer-list li a {
    font-size: 0.8315rem;
  }

  .copyright {
    font-size: 0.8rem;
  }

  .address {
    font-size: 0.925rem;
  }

  .contact {
    font-size: 0.8rem;
    white-space: nowrap;
  }

  .cert {
    font-size: 0.8rem;
  }

  .qualiopi-footer-img {
    min-width: 130% !important;
  }

  .justify-content-between {
    justify-content: unset !important;
  }
}
</style>
